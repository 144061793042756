/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useFormik } from 'formik';
import moment from 'moment';

import { ExpandMoreRounded } from '@mui/icons-material/';
import iconHouse from '../../../assets/icons/generals/house.svg';
import iconCalendar from '../../../assets/icons/generals/calendar2.svg';

import LineGrid from '../LineGrid';
import SimpleSelect from '../../SimpleSelect/SimpleSelect';
import GridRevenueShimmer from '../LoadingShimmer/GridRevenue/GridRevenueShimmer';
import Tag from './Tag';
import Tooltip from '../../Tooltip';
import ModalFinancialExtract from '../OwnerExtract/ModalFinancialExtract';

import { PropertyOwner } from '../../../services/Owner/types';
import { getProperPayOwnerDashboard } from '../../../services/Owner/Dashboard/request';
import { getOwnerExtract } from '../../../services/Owner/OwnerExtract/request';

import { compareList } from '../../../utils/Sorting';
import { useToastErrorMessage } from '../../../utils/Messages';

import { useTag } from '../../../hooks/OwnerPage/OwnerGridFinances/useTag';
import { useOwnerExtract } from '../../../hooks/OwnerPage/OwnerExtract/useOwnerExtract';
import { useViewMode } from '../../../context/ViewMode/ViewMode';

import {
  ButtonDownload,
  Container,
  GridContainer,
  GridContent,
  ContainerSelect,
  ContentSelectProperty,
  ContentSelectYear,
  Header,
  GridHeaderContainer,
  GridHeaderContent,
  Title,
  ExtractButtonContainer,
  ContainerBottom,
} from './styles';

import { getMonthNumber, NEW_DATE_DEFAULT } from '../../../utils/Date';
import { ProperPayOwnerDashboardResponse } from '../../../services/Owner/Dashboard/types';
import { initialValuesOwnerDashboard } from '../../../services/Owner/Dashboard/utils';
import { useUser } from '../../../context/UserContext';
import FeedbackButton from './FeedbackButton';

interface GridProps {
  propertiesList?: PropertyOwner[];
  isLoading: boolean;
}

const GridRevenue = ({ propertiesList, isLoading }: GridProps) => {
  const { userInformation } = useUser();
  const { mode } = useViewMode();

  const [selectedDates] = useState(new Date());
  const [dates, setDates] = useState<Array<number>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const months: string[] = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  const [indexCurrentMonth, setIndexCurrentMonth] = useState<number>(new Date().getMonth() + 1);
  const [currentMonth, setCurrentMonth] = useState<string>(months[indexCurrentMonth - 1]);

    type SelectCodesProps = {
      id?: number,
      value?: number,
      valueLabel?: string,
    };

    const [propertiesCodesList, setPropertiesCodesList] = useState<SelectCodesProps[]>();

    const {
      isExpandedDailyExecuted,
      isExpandedDailyPaid,
      isExpandedPayout,
      isExpandedWallet,
      handleChangeIsExpandedDailyExecuted,
      handleChangeIsExpandedDailyPaid,
      handleChangeIsExpandedPayout,
      handleChangeIsExpandedWallet,
    } = useTag();

    const {
      setExtractList,
      setPeriod,
      setProperty,
      openModalFinancialExtract,
      setOpenModalFinancialExtract,
    } = useOwnerExtract();

    const [
      financialClosingData,
      setFinancialClosingData,
    ] = useState<ProperPayOwnerDashboardResponse>(initialValuesOwnerDashboard);

    const toastErrorRequest = useToastErrorMessage();

    const formik = useFormik({
      initialValues: {
        year: new Date().getFullYear(),
        property: undefined,
        monthCurrent: currentMonth,
      },
      onSubmit: async () => {},
    });

    const ownerId = useMemo(() => (userInformation?.owner?.id || undefined), [userInformation]);
    const viewModeOwnerId = useMemo(() => mode?.user_info?.owner?.id || undefined, [mode]);
    const year = useMemo(() => Number(formik.values.year), [formik.values.year]);
    const propertyId = useMemo(() => (formik.values.property
      ? Number(formik.values.property) : undefined), [formik.values.property]);

    useEffect(() => {
      const propFiltered = propertiesCodesList?.filter((code) => code?.value === propertyId) || [];
      if (propFiltered.length !== 0) {
        setProperty({
          id: propFiltered?.[0]?.value || -1,
          code: propFiltered?.[0]?.valueLabel || '',
        });
      }
    }, [propertiesCodesList, formik.values.property]);

    const getFinancialClosing = async () => {
      try {
        setLoading(true);

        if (year && viewModeOwnerId) {
          const response = await getProperPayOwnerDashboard(
            year, propertyId, viewModeOwnerId,
          );
          setFinancialClosingData(response);
        } else if (year && ownerId) {
          const response = await getProperPayOwnerDashboard(
            year, propertyId, ownerId,
          );
          setFinancialClosingData(response);
        }

        setLoading(false);

        handleChangeIsExpandedDailyExecuted(false);
        handleChangeIsExpandedDailyPaid(false);
        handleChangeIsExpandedPayout(false);
        handleChangeIsExpandedWallet(false);
      } catch (error) {
        setLoading(false);
        toastErrorRequest(error);
      }
    };

    const defineDates = (dateStart: number) => {
      const arrayDates = [];
      for (let i = dateStart; i <= selectedDates.getFullYear() + 1; i += 1) {
        arrayDates.push(i);
      }
      setDates(arrayDates.reverse());
    };

    const handleClickMonthYear = async (monthShow: number, yearShow: number) => {
      if (propertyId) {
        const date = `${yearShow}-${getMonthNumber[monthShow]}`;

        const response = await getOwnerExtract({
          date,
          property_id: propertyId,
        });

        setExtractList(response);
        setPeriod(moment(date).toDate());
        setOpenModalFinancialExtract(true);
      }
    };

    useEffect(() => {
      if (propertyId && propertiesList) {
        const prop = propertiesList.filter((item) => item.id === propertyId);
        if (prop.length > 0) {
          const contractDate = prop[0]?.contract_start_date || '2011-01-01';
          defineDates(Number(contractDate.split('-')[0]));
        }
      } else if (propertiesList) {
        const contractDates: number[] = [];
        propertiesList.forEach((item) => {
          const contractDate = item?.contract_start_date || '2011-01-01';
          contractDates.push(Number(contractDate.split('-')[0]));
        });
        contractDates.sort();
        if (contractDates.length > 0) {
          defineDates(contractDates[0]);
        }
      }
    }, [formik.values.property, propertiesList]);

    useEffect(() => {
      if (propertiesList) {
        const codes = propertiesList.map((prop) => ({
          id: prop.id,
          value: prop.id,
          valueLabel: prop.code,
        }));

        codes.sort((a, b) => compareList((a?.valueLabel || ''), (b?.valueLabel || '')));

        if (codes.length === 1) {
          formik.setFieldValue('property', codes[0].id);
        }

        setPropertiesCodesList(codes);
      }
    }, [propertiesList]);

    useEffect(() => {
      if (isLoading) return;
      getFinancialClosing();
    }, [isLoading, year, propertyId, viewModeOwnerId]);

    useEffect(() => {
      setCurrentMonth(formik.values.monthCurrent);
      setIndexCurrentMonth(months.indexOf(formik.values.monthCurrent) + 1);
    }, [formik.values.monthCurrent]);

    const isAfterOctober2022 = (dateStr: string) => {
      const selectedDate = new Date(dateStr);
      const october2022 = new Date('2022-10-01');
      return selectedDate >= october2022;
    };

    const extractButtonIsActive = (monthId: number) => {
      if (!propertyId) return false;

      const yearCurrent = NEW_DATE_DEFAULT.getFullYear();
      if (year < yearCurrent) return false;

      const monthCurrentYearCurrentDate = `${year}-${getMonthNumber[monthId]}-01`;
      const monthFuture = NEW_DATE_DEFAULT.getMonth() + 1;

      return isAfterOctober2022(monthCurrentYearCurrentDate) && monthId < monthFuture;
    };

    return (
      <>
        <Container>
          <Header>
            <ContainerBottom>
              <Title data-cy="title-grid-revenue">Financeiro</Title>
              <div><FeedbackButton /></div>
            </ContainerBottom>

            <ContainerSelect>
              <ContentSelectProperty>
                <strong>Selecione o imóvel que deseja filtrar</strong>
                <SimpleSelect
                  dataCy="select-properties-list"
                  id="property"
                  placeholder="Todos"
                  formik={formik}
                  icon={iconHouse}
                  showIcon
                  options={propertiesCodesList || []}
                />
              </ContentSelectProperty>
              <ContentSelectYear>
                <strong>Selecione o ano que deseja filtrar</strong>
                <SimpleSelect
                  dataCy="select-year-list"
                  id="year"
                  placeholder="Selecione"
                  formik={formik}
                  icon={iconCalendar}
                  showIcon
                  options={(dates || [])
                    .filter((itemYear) => itemYear > 2018)
                    .map((itemYear) => ({
                      value: itemYear,
                      valueLabel: itemYear,
                    }))}
                />
              </ContentSelectYear>
              <ButtonDownload type="button">Baixar relatórios</ButtonDownload>
            </ContainerSelect>
          </Header>

          {loading ? (
            <GridRevenueShimmer />
          ) : (
            <GridContainer>
              <GridContent>
                <GridHeaderContainer>
                  <GridHeaderContent>
                    <span className="txt-origin">Origem</span>
                  </GridHeaderContent>
                  <p className="spacing" />

                  {months.map((month, index) => (
                    <GridHeaderContent key={month}>
                      <p className={index > new Date().getMonth() && year === new Date().getFullYear() ? 'highLigth' : ''}>{month}</p>
                      {extractButtonIsActive(index) && (
                        <ExtractButtonContainer
                          id="btn-financial-statement"
                          isVisible
                        >
                          <Tooltip text="Visualizar extrato">
                            <ExpandMoreRounded
                              onClick={() => handleClickMonthYear(index, year)}
                              id={`view-financial-statement-button-${month}`}
                            />
                          </Tooltip>
                        </ExtractButtonContainer>
                      )}
                    </GridHeaderContent>
                  ))}

                  <GridHeaderContent>
                    <p className="txt-total">{'Total'}</p>
                  </GridHeaderContent>
                </GridHeaderContainer>

                <Tag
                  label="Diárias Executadas"
                  variant="1"
                  isExpanded={isExpandedDailyExecuted}
                  setIsExpanded={handleChangeIsExpandedDailyExecuted}
                  isSelectedOptionTODOS={propertyId === undefined}
                  valueJan={financialClosingData.executed_dailys['1']}
                  valueFeb={financialClosingData.executed_dailys['2']}
                  valueMar={financialClosingData.executed_dailys['3']}
                  valueApr={financialClosingData.executed_dailys['4']}
                  valueMay={financialClosingData.executed_dailys['5']}
                  valueJun={financialClosingData.executed_dailys['6']}
                  valueJul={financialClosingData.executed_dailys['7']}
                  valueAug={financialClosingData.executed_dailys['8']}
                  valueSep={financialClosingData.executed_dailys['9']}
                  valueOut={financialClosingData.executed_dailys['10']}
                  valueNov={financialClosingData.executed_dailys['11']}
                  valueDec={financialClosingData.executed_dailys['12']}
                  totalValue={financialClosingData.executed_dailys.total}
                />
                <LineGrid
                  isVisible={isExpandedDailyExecuted}
                  isSelectedOptionTODOS={propertyId === undefined}
                  title="A receber no mês atual"
                  valueJan={financialClosingData.to_receive['1']}
                  valueFeb={financialClosingData.to_receive['2']}
                  valueMar={financialClosingData.to_receive['3']}
                  valueApr={financialClosingData.to_receive['4']}
                  valueMay={financialClosingData.to_receive['5']}
                  valueJun={financialClosingData.to_receive['6']}
                  valueJul={financialClosingData.to_receive['7']}
                  valueAug={financialClosingData.to_receive['8']}
                  valueSep={financialClosingData.to_receive['9']}
                  valueOut={financialClosingData.to_receive['10']}
                  valueNov={financialClosingData.to_receive['11']}
                  valueDec={financialClosingData.to_receive['12']}
                  totalValue={financialClosingData.to_receive.total}
                />
                <LineGrid
                  isVisible={isExpandedDailyExecuted}
                  isSelectedOptionTODOS={propertyId === undefined}
                  title="A receber no mês seguinte"
                  valueJan={financialClosingData.to_receive_following_month['1']}
                  valueFeb={financialClosingData.to_receive_following_month['2']}
                  valueMar={financialClosingData.to_receive_following_month['3']}
                  valueApr={financialClosingData.to_receive_following_month['4']}
                  valueMay={financialClosingData.to_receive_following_month['5']}
                  valueJun={financialClosingData.to_receive_following_month['6']}
                  valueJul={financialClosingData.to_receive_following_month['7']}
                  valueAug={financialClosingData.to_receive_following_month['8']}
                  valueSep={financialClosingData.to_receive_following_month['9']}
                  valueOut={financialClosingData.to_receive_following_month['10']}
                  valueNov={financialClosingData.to_receive_following_month['11']}
                  valueDec={financialClosingData.to_receive_following_month['12']}
                  totalValue={financialClosingData.to_receive_following_month.total}
                />

                <Tag
                  label="Diárias Pagas"
                  variant="1"
                  isExpanded={isExpandedDailyPaid}
                  setIsExpanded={handleChangeIsExpandedDailyPaid}
                  isSelectedOptionTODOS={propertyId === undefined}
                  valueJan={financialClosingData.paid_dailys['1']}
                  valueFeb={financialClosingData.paid_dailys['2']}
                  valueMar={financialClosingData.paid_dailys['3']}
                  valueApr={financialClosingData.paid_dailys['4']}
                  valueMay={financialClosingData.paid_dailys['5']}
                  valueJun={financialClosingData.paid_dailys['6']}
                  valueJul={financialClosingData.paid_dailys['7']}
                  valueAug={financialClosingData.paid_dailys['8']}
                  valueSep={financialClosingData.paid_dailys['9']}
                  valueOut={financialClosingData.paid_dailys['10']}
                  valueNov={financialClosingData.paid_dailys['11']}
                  valueDec={financialClosingData.paid_dailys['12']}
                  totalValue={financialClosingData.paid_dailys.total}
                />
                <LineGrid
                  isVisible={isExpandedDailyPaid}
                  isSelectedOptionTODOS={propertyId === undefined}
                  title="Diárias do mês atual"
                  valueJan={financialClosingData.revenue['1']}
                  valueFeb={financialClosingData.revenue['2']}
                  valueMar={financialClosingData.revenue['3']}
                  valueApr={financialClosingData.revenue['4']}
                  valueMay={financialClosingData.revenue['5']}
                  valueJun={financialClosingData.revenue['6']}
                  valueJul={financialClosingData.revenue['7']}
                  valueAug={financialClosingData.revenue['8']}
                  valueSep={financialClosingData.revenue['9']}
                  valueOut={financialClosingData.revenue['10']}
                  valueNov={financialClosingData.revenue['11']}
                  valueDec={financialClosingData.revenue['12']}
                  totalValue={financialClosingData.revenue.total}
                />
                <LineGrid
                  isVisible={isExpandedDailyPaid}
                  isSelectedOptionTODOS={propertyId === undefined}
                  title="Diárias do mês anterior"
                  valueJan={financialClosingData.revenue_prior_month['1']}
                  valueFeb={financialClosingData.revenue_prior_month['2']}
                  valueMar={financialClosingData.revenue_prior_month['3']}
                  valueApr={financialClosingData.revenue_prior_month['4']}
                  valueMay={financialClosingData.revenue_prior_month['5']}
                  valueJun={financialClosingData.revenue_prior_month['6']}
                  valueJul={financialClosingData.revenue_prior_month['7']}
                  valueAug={financialClosingData.revenue_prior_month['8']}
                  valueSep={financialClosingData.revenue_prior_month['9']}
                  valueOut={financialClosingData.revenue_prior_month['10']}
                  valueNov={financialClosingData.revenue_prior_month['11']}
                  valueDec={financialClosingData.revenue_prior_month['12']}
                  totalValue={financialClosingData.revenue_prior_month.total}
                />

                <Tag
                  label="Saídas"
                  variant="2"
                  isExpanded={isExpandedPayout}
                  setIsExpanded={handleChangeIsExpandedPayout}
                  isSelectedOptionTODOS={propertyId === undefined}
                  valueJan={financialClosingData.outgoing['1']}
                  valueFeb={financialClosingData.outgoing['2']}
                  valueMar={financialClosingData.outgoing['3']}
                  valueApr={financialClosingData.outgoing['4']}
                  valueMay={financialClosingData.outgoing['5']}
                  valueJun={financialClosingData.outgoing['6']}
                  valueJul={financialClosingData.outgoing['7']}
                  valueAug={financialClosingData.outgoing['8']}
                  valueSep={financialClosingData.outgoing['9']}
                  valueOut={financialClosingData.outgoing['10']}
                  valueNov={financialClosingData.outgoing['11']}
                  valueDec={financialClosingData.outgoing['12']}
                  totalValue={financialClosingData.outgoing.total}
                />
                <LineGrid
                  isVisible={isExpandedPayout}
                  isSelectedOptionTODOS={propertyId === undefined}
                  title="Despesas"
                  valueJan={financialClosingData.expenses['1']}
                  valueFeb={financialClosingData.expenses['2']}
                  valueMar={financialClosingData.expenses['3']}
                  valueApr={financialClosingData.expenses['4']}
                  valueMay={financialClosingData.expenses['5']}
                  valueJun={financialClosingData.expenses['6']}
                  valueJul={financialClosingData.expenses['7']}
                  valueAug={financialClosingData.expenses['8']}
                  valueSep={financialClosingData.expenses['9']}
                  valueOut={financialClosingData.expenses['10']}
                  valueNov={financialClosingData.expenses['11']}
                  valueDec={financialClosingData.expenses['12']}
                  totalValue={financialClosingData.expenses.total}
                />
                <LineGrid
                  isVisible={isExpandedPayout}
                  isSelectedOptionTODOS={propertyId === undefined}
                  title="Comissão"
                  valueJan={financialClosingData.commission['1']}
                  valueFeb={financialClosingData.commission['2']}
                  valueMar={financialClosingData.commission['3']}
                  valueApr={financialClosingData.commission['4']}
                  valueMay={financialClosingData.commission['5']}
                  valueJun={financialClosingData.commission['6']}
                  valueJul={financialClosingData.commission['7']}
                  valueAug={financialClosingData.commission['8']}
                  valueSep={financialClosingData.commission['9']}
                  valueOut={financialClosingData.commission['10']}
                  valueNov={financialClosingData.commission['11']}
                  valueDec={financialClosingData.commission['12']}
                  totalValue={financialClosingData.commission.total}
                />

                <Tag
                  label="Resultado"
                  variant="3"
                  isExpanded={isExpandedWallet}
                  setIsExpanded={handleChangeIsExpandedWallet}
                  isSelectedOptionTODOS={propertyId === undefined}
                  valueJan={financialClosingData.results['1']}
                  valueFeb={financialClosingData.results['2']}
                  valueMar={financialClosingData.results['3']}
                  valueApr={financialClosingData.results['4']}
                  valueMay={financialClosingData.results['5']}
                  valueJun={financialClosingData.results['6']}
                  valueJul={financialClosingData.results['7']}
                  valueAug={financialClosingData.results['8']}
                  valueSep={financialClosingData.results['9']}
                  valueOut={financialClosingData.results['10']}
                  valueNov={financialClosingData.results['11']}
                  valueDec={financialClosingData.results['12']}
                  totalValue={financialClosingData.results.total}
                />
                <LineGrid
                  isVisible={isExpandedWallet}
                  isSelectedOptionTODOS={propertyId === undefined}
                  title="Repasse (TED)"
                  transfer
                  valueJan={financialClosingData.transfer['1']}
                  valueFeb={financialClosingData.transfer['2']}
                  valueMar={financialClosingData.transfer['3']}
                  valueApr={financialClosingData.transfer['4']}
                  valueMay={financialClosingData.transfer['5']}
                  valueJun={financialClosingData.transfer['6']}
                  valueJul={financialClosingData.transfer['7']}
                  valueAug={financialClosingData.transfer['8']}
                  valueSep={financialClosingData.transfer['9']}
                  valueOut={financialClosingData.transfer['10']}
                  valueNov={financialClosingData.transfer['11']}
                  valueDec={financialClosingData.transfer['12']}
                  totalValue={financialClosingData.transfer.total}
                />
                <LineGrid
                  isVisible={isExpandedWallet}
                  isSelectedOptionTODOS={propertyId === undefined}
                  title="Saldo"
                  valueJan={financialClosingData.final_balance['1']}
                  valueFeb={financialClosingData.final_balance['2']}
                  valueMar={financialClosingData.final_balance['3']}
                  valueApr={financialClosingData.final_balance['4']}
                  valueMay={financialClosingData.final_balance['5']}
                  valueJun={financialClosingData.final_balance['6']}
                  valueJul={financialClosingData.final_balance['7']}
                  valueAug={financialClosingData.final_balance['8']}
                  valueSep={financialClosingData.final_balance['9']}
                  valueOut={financialClosingData.final_balance['10']}
                  valueNov={financialClosingData.final_balance['11']}
                  valueDec={financialClosingData.final_balance['12']}
                  totalValue={financialClosingData.final_balance.total}
                  hideTotal
                />
              </GridContent>
            </GridContainer>
          )}
        </Container>

        <ModalFinancialExtract
          openModal={openModalFinancialExtract}
          setOpenModal={setOpenModalFinancialExtract}
        />
      </>
    );
};

GridRevenue.defaultProps = {
  propertiesList: [],
};

export default GridRevenue;
