import { Button } from '@mui/material';
import Header from './Header';
import TedListFab from './Header/Fab/TedListFab';

import { useMobile } from '../../hooks/useMobile/useMobile';

import { Card, Container, ContainerButton } from './styles';
import { Text } from '../Text';
import { useToast } from '../../context/ToastContext';

const HostTedList = () => {
  const toast = useToast();
  const { isMobile } = useMobile();

  const handleDownload = () => {
    try {
      throw new Error('Falha no download');
    } catch (e) {
      toast.error('Erro no upload. Verifique os dados da planilha.');
    }
  };

  const handleUpload = () => {
    try {
      throw new Error('Falha no upload');
    } catch (e) {
      toast.error('Erro no download do template');
    }
  };

  if (isMobile) {
    return (
      <Container>
        <TedListFab />
        <Card>
          <div>
            <Text $color="#000" $fontSize="16" $fontWeight="700">
              Template para lista de Teds
            </Text>
            <div style={{ margin: '16px' }} />
            <Text $color="#000" $fontSize="14" $fontWeight="500">
              Baixe o template para preenchimento das TEDs
            </Text>
          </div>
          <ContainerButton>
            <Button onClick={handleDownload} variant="contained" disabled>
              <h1>Download do template</h1>
            </Button>
          </ContainerButton>
        </Card>
        <Card>
          <div>
            <Text $color="#000" $fontSize="16" $fontWeight="700">
              Atualização de valores de Teds
            </Text>
            <div style={{ margin: '16px' }} />
            <Text $color="#000" $fontSize="14" $fontWeight="500">
              Envie o arquivo preenchido com as TEDs
            </Text>
          </div>
          <ContainerButton>
            <Button onClick={handleUpload} variant="contained" disabled>
              <h1>Upload de teds</h1>
            </Button>
          </ContainerButton>
        </Card>
        {/* <Grid /> */}
      </Container>
    );
  }

  return (
    <Container>
      <Header />
      <Card>
        <div>
          <Text $color="#000" $fontSize="16" $fontWeight="700">
            Template para lista de Teds
          </Text>
          <div style={{ margin: '16px' }} />
          <Text $color="#000" $fontSize="14" $fontWeight="500">
            Baixe o template para preenchimento das TEDs
          </Text>
        </div>
        <ContainerButton>
          <Button onClick={handleDownload} disabled variant="contained">
            <h1>Download do template</h1>
          </Button>
        </ContainerButton>
      </Card>
      <Card>
        <div>
          <Text $color="#000" $fontSize="16" $fontWeight="700">
            Atualização de valores de Teds
          </Text>
          <div style={{ margin: '16px' }} />
          <Text $color="#000" $fontSize="14" $fontWeight="500">
            Envie o arquivo preenchido com as TEDs
          </Text>
        </div>
        <ContainerButton>
          <Button onClick={handleUpload} disabled variant="contained">
            <h1>Upload de teds</h1>
          </Button>
        </ContainerButton>
      </Card>
      {/* <Grid /> */}
    </Container>
  );
};

export { HostTedList };
