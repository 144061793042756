/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';
import { Grid } from '@mui/material';

import AlertIcon from '../../../../assets/icons/generals/alert.svg';
import TrashIcon from '../../../../assets/icons/generals/bashRed.svg';
import SearchIcon from '../../../../assets/icons/generals/search.svg';
import addPhotoSketchIcon from '../../../../assets/icons/Onboarding/photoSketch.png';

import {
  FileAWSFormat,
  FileProps,
  FileReference,
  FileReferenceResponse,
} from '../../../../context/FileContext/types';

import { AddressDetail } from '../../../../services/Address/types';
import { RequestHost } from '../../../../services/Host/types';
import { getHosts } from '../../../../services/Host/request';
import { postAddress, deleteAddress } from '../../../../services/Address/request';
import {
  deletePropertyManager,
  deletePropertyRules,
  postPropertyHandover,
  postPropertyManager,
  postPropertyRules,
} from '../../../../services/Onboarding/request';

import { useFile } from '../../../../hooks/FileHook/useFile';
import { useToast } from '../../../../context/ToastContext';
import { useToastErrorMessage } from '../../../../utils/Messages';
import { useOnboardingHandover } from '../../../../context/OnboardingContext/OnboardingHandoverContext';
import { useOnboardingPage } from '../../../../hooks/useOnboarding/useOnboarding';
import { PlanOptions, PropertyInformation, Values } from '../../../../context/OnboardingContext/types';
import Tooltip from '../../../Tooltip';

import {
  currencyToNumber,
  formatedDateOfRequest,
  numberToCurrency,
} from '../../../../utils/Formatter';

import Upload from '../../../Upload';

import FormButton from '../../../FormButton';
import FormGridRow from '../../../FormGridRow';
import OnboardingSwitch from '../../OnboardingSwitch';
import OnboardingTextField from '../../OnboardingTextArea';
import SimpleSelect from '../../../SimpleSelect/SimpleSelect';
import OnboardingPaymentMethod from '../../OnboardingPaymentMethod';

import { ResultsText } from '../CardOwner/styles';
import { ErrorIcon } from '../../../TextField/styles';

import {
  End,
  Title,
  Border,
  // SubText,
  Percent,
  BoxTitle,
  SearchBar,
  BoxHeader,
  // QuadriBox,
  ButtonDiv,
  BoxContent,
  UploadFile,
  UploadText,
  TrashButton,
  BoxContainer,
  AreaSelected,
  ButtonSection,
  TitleContainer,
  TotalValueText,
  ErrorContainer,
  AlertContainer,
  // TotalValuePrice,
  UploadContainer,
  SwitchContainer,
  ContentContainer,
  SearchContainer,
  CardHostSelected,
  // TotalValueBorder,
  TotalBoxContainer,
  SearchBoxContainer,
  SelectBoxContainer,
  DescriptionContainer,
  DeploymentFeeValuesContainer,
} from './styles';

import { PaymentMethod, PipedriveOwnerInfo } from '../../../../services/Onboarding/types';
import { GetOwnersData } from '../../../../services/InsertData/types';
import CustomInputMask from '../../../CustomInputMask';
import { postOwnerInvoice } from '../../../../services/Owner/request';

interface Props {
  endCallback: () => void;
}

const photoInitialValues: FileProps = {
  size: 0,
  name: '',
  id: uuid(),
  error: false,
  MIMEtype: '',
  uid: undefined,
  uploaded: false,
  uploadProgress: 0,
  readableSize: '0 KB',
  url: addPhotoSketchIcon,
  file: addPhotoSketchIcon,
  previewURL: addPhotoSketchIcon,
};

const CardDeploymentFeeValues = ({ endCallback }: Props) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [documentClicked, setDocumentClicked] = useState(false);
  const [hostRequired, setHostRequired] = useState<boolean>(false);
  const [hostsList, setHostsList] = useState<Array<RequestHost>>([]);
  const [photos, setPhotos] = useState<FileProps>(photoInitialValues);
  const [listFiltered, setListFiltered] = useState<Array<RequestHost>>([]);
  const [documentPhotoRequired, setDocumentPhotoRequired] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();

  const {
    handleClickPhase,
  } = useOnboardingPage();

  const {
    deleteFile,
    uploadedFiles,
    uploadFileToS3,
    createFileReference,
  } = useFile();

  const {
    dates,
    dealID,
    values,
    setOwner,
    setValues,
    setIdNewOwner,
    infosPipeDrive,
    propertyDetails,
    setInfosPipeDrive,
    setPropertyDetails,
    owner,
  } = useOnboardingHandover();

  const ownerCountryIsBrazil = useMemo(() => {
    const country = `${owner?.user?.main_address?.country}`.trim().toLowerCase();
    return ['brasil', 'br'].includes(country); 
  }, [owner]);


  const handleUploadFile = async (file: FileProps) => {
    let fileResponse: FileReferenceResponse | { uid: null } = { uid: null };

    try {
      const fileReference: FileReference = {
        category: 'document',
        name: file.name,
        content_type: file.MIMEtype,
      };

      const responseFile: FileReferenceResponse = await createFileReference(fileReference);

      const params: FileAWSFormat = {
        file: photos.file,
        fileId: responseFile.uid,
        url: responseFile.storage.url,
        acl: responseFile.storage.fields.acl,
        key: responseFile.storage.fields.key,
        content_type: fileReference.content_type,
        policy: responseFile.storage.fields.policy,
        signature: responseFile.storage.fields.signature,
        AWSAccessKeyId: responseFile.storage.fields.AWSAccessKeyId,
      };

      await uploadFileToS3(file, params);

      fileResponse = {
        ...responseFile,
        storage: {
          ...responseFile.storage,
          fields: {
            ...responseFile.storage.fields,
          },
        },
      };
    } catch (e: unknown) {
      toast.error('Não foi possível fazer o upload do documento!');
    }

    return fileResponse;
  };

  const handleSelectFile = async (file: FileProps) => {
    if (!documentClicked) {
      setDocumentClicked(true);

      let responseDocumentFile: FileReferenceResponse | { uid: null } = { uid: null };
      responseDocumentFile = await handleUploadFile(file);

      if (!responseDocumentFile.uid) {
        toast.error('Não foi possível realizar o upload do arquivo do documento');
      } else {
        setPhotos((prevFile) => ({
          ...prevFile,
          ...file,
          uid: responseDocumentFile?.uid || '',
        }));
      }
    }
  };

  const validation = Yup.object().shape({
    plan: Yup.string().required(),
    // setupValue: Yup.string().required(),
    // bedlinenPrice: Yup.string().required(),
    // photographerValue: Yup.string().required(),
    // fullInspectionValue: Yup.string().required(),
    totalImplantationFee: Yup.string().required('O valor total é obrigatório'),
    commissions: Yup.number().required().max(100, 'Valor não pode ultrapassar 100'),
    hostReservationComissionFee:
            Yup
              .number()
              .typeError('O valor deve ser um número')
              .required('O campo é obrigatório')
              .test(
                'comission-host',
                'Taxa de comissão zerada é permitida apenas para Gestão Digital',
                function validate(value) {
                  const { parent } = this;
                  if (Number(value) <= 0 && parent.plan !== 'Digital_Management') return false;
                  return true;
                },
              )
              .max(100, 'Valor não pode ultrapassar 100'),
  });

  const isValid = (photoUid?: string, hostId?: number) => {
    if (documentPhotoRequired && photoUid === undefined) {
      setDocumentPhotoRequired(true);
      return false;
    }

    if (hostId === undefined) {
      setHostRequired(true);
      return false;
    }
    return true;
  };

  type FormikProps = {
    host?: number,
    plan: PlanOptions,
    // setupValue: string,
    description: string,
    commissions: number,
    haveBedding: boolean,
    paymentMethod: string,
    // bedlinenPrice: string,
    qtdInstallments: number,
    // photographerValue: string,
    // fullInspectionValue: string,
    totalImplantationFee: string,
    valueBalanceDiscountRate: number,
    hostReservationComissionFee: number,
  };

  const setFormikBackup = (formikValues: FormikProps) => {
    localStorage.setItem('@formikBackup', JSON.stringify({
      plan: formikValues.plan,
      host: formikValues.host,
      // // setupValue: formikValues.setupValue,
      commissions: formikValues.commissions,
      description: formikValues.description,
      haveBedding: formikValues.haveBedding,
      // // bedlinenPrice: formikValues.bedlinenPrice,
      paymentMethod: formikValues.paymentMethod,
      // // photographerValue: formikValues.photographerValue,
      // // fullInspectionValue: formikValues.fullInspectionValue,
      photo: photos?.uid !== undefined ? photos?.uid : null,
      totalImplantationFee: formikValues.totalImplantationFee,
      hostReservationComissionFee: formikValues.hostReservationComissionFee,
    }));
  };

  const getFormikBackup = () => {
    let data: any = localStorage.getItem('@formikBackup');

    if (data) {
      data = JSON.parse(data);
    }

    return {
      host: data?.host || undefined,
      // // setupValue: data?.setupValue || '',
      description: data?.description || '',
      commissions: data?.commissions || 30,
      haveBedding: data?.haveBedding || false,
      plan: (data?.plan || '') as PlanOptions,
      // // bedlinenPrice: data?.bedlinenPrice || '',
      paymentMethod: data?.paymentMethod || '1',
      // // photographerValue: data?.photographerValue || '',
      // // fullInspectionValue: data?.fullInspectionValue || '',
      totalImplantationFee: data?.totalImplantationFee || '',
      hostReservationComissionFee: data?.hostReservationComissionFee || '',
    } as FormikProps;
  };

  const removeFormikBackup = () => {
    localStorage.removeItem('@formikBackup');
  };

  const initialValues: FormikProps = useMemo(() => ({
    qtdInstallments: 0,
    valueBalanceDiscountRate: 100,
    host: getFormikBackup().host,
    // // setupValue: getFormikBackup().setupValue,
    haveBedding: getFormikBackup().haveBedding,
    commissions: getFormikBackup().commissions,
    description: getFormikBackup().description,
    // // bedlinenPrice: getFormikBackup().bedlinenPrice,
    paymentMethod: getFormikBackup().paymentMethod,
    // // photographerValue: getFormikBackup().photographerValue,
    // // fullInspectionValue: getFormikBackup().fullInspectionValue,
    totalImplantationFee: getFormikBackup().totalImplantationFee
      || String(infosPipeDrive?.implantation_fees?.implantation_total_value),
    plan: infosPipeDrive?.implantation_fees?.plan_choosed as PlanOptions || getFormikBackup().plan,
    hostReservationComissionFee: 8,
  }), [infosPipeDrive]);

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: async (formValues) => {
      if (!isValid(photos?.uid, formValues?.host)) return;

      let propertyAddress: AddressDetail = {
        id: -1,
        country: 'BR',
        city: propertyDetails.city,
        state: propertyDetails.state,
        street: propertyDetails.street,
        number: propertyDetails.number,
        complement: propertyDetails.complement,
        condominium: propertyDetails.condominium,
        postal_code: propertyDetails.postal_code,
        neighborhood: propertyDetails.neighborhood,
      };

      try {
        propertyAddress = await postAddress({
          city: propertyAddress.city,
          state: propertyAddress.state,
          number: propertyAddress.number,
          street: propertyAddress.street,
          country: propertyAddress.country,
          complement: propertyAddress.complement,
          condominium: propertyAddress.condominium,
          postal_code: propertyAddress.postal_code,
          neighborhood: propertyAddress.neighborhood,
        });

        const chooseCnpjOrCpf = () => {
          if (owner?.user?.cnpj) return { cnpj: owner?.user?.cnpj };
          if (owner?.user?.cpf) return { cpf: owner?.user?.cpf };
          return {};
        };

        await postOwnerInvoice({
          ...chooseCnpjOrCpf(),
          address: !ownerCountryIsBrazil ? propertyAddress.street : owner?.user?.main_address?.street,
          address_number: !ownerCountryIsBrazil ? propertyAddress.number : owner?.user?.main_address?.number,
          city: !ownerCountryIsBrazil ? propertyAddress.city : owner?.user?.main_address?.city,
          complement: !ownerCountryIsBrazil ? propertyAddress.complement : owner?.user?.main_address?.complement,
          state: !ownerCountryIsBrazil ? propertyAddress.state : owner?.user?.main_address?.state,
          district: !ownerCountryIsBrazil ? propertyAddress.neighborhood : owner?.user?.main_address?.neighborhood,
          postal_code: !ownerCountryIsBrazil ? propertyAddress.postal_code : owner?.user?.main_address?.postal_code,
          phone_number: owner?.user?.phone_number1,
          email: owner?.user?.email,
          user:  owner?.user?.id,
          invoice_entity_name: `${owner?.user?.first_name} ${owner?.user?.last_name}`,
        });

        try {
          const property = await postPropertyManager({
            address: propertyAddress.id,
            host: formValues.host,
            code: `${propertyDetails.propertycode}`,
            lavatory_quantity: propertyDetails.lavabo,
            comission_fee: `${formValues.commissions / 100}`,
            king_bed_quantity: propertyDetails.kingBeds,
            property_type: propertyDetails.propertyType,
            bathroom_quantity: propertyDetails.bathrooms,
            queen_bed_quantity: propertyDetails.queenBeds,
            guest_capacity: propertyDetails.guestCapacity,
            single_bed_quantity: propertyDetails.singleBeds,
            double_bed_quantity: propertyDetails.doubleBeds,
            bedroom_quantity: propertyDetails.bedroomsAmount,
            single_sofa_bed_quantity: propertyDetails.singleSofaBeds,
            double_sofa_bed_quantity: propertyDetails.sofaDoubleBeds,
            category_location: parseInt(propertyDetails.category, 10),
            contract_start_date: formatedDateOfRequest(dates.start_date),
            balance_discount_rate: formValues.paymentMethod === 'Discount_Rate'
              ? `${(formValues.valueBalanceDiscountRate / 100)}` : '0',
            status: 'Onboarding',
            owners: [`${owner.id}`],
            owner: owner.id,
            host_reservation_comission_fee: formik.values.plan === 'Digital_Management' ? 0 : (formik.values.hostReservationComissionFee / 100),
            host_cleaning_comission_fee: formik.values.plan === 'Digital_Management' ? 0 : 1,
            extra_day_preparation: propertyDetails.extraDayPreparation,
          });

          try {
            const rules = await postPropertyRules({
              allow_pet: false,
              property: property.id,
              check_in_time: '11:00',
              check_out_time: '15:00',
              events_permitted: false,
              smoking_permitted: false,
              suitable_for_babies: false,
              suitable_for_children: false,
            });

            try {
              await postPropertyHandover({
                rules: rules.id,
                plan: formValues.plan,
                property: property.id,
                pipedrive_deal_id: dealID || 0,
                comment: propertyDetails.comment,
                // setup_value: currencyToNumber(formValues.setupValue),
                implatation_items_description: formValues.description,
                onboarding_contact_name: propertyDetails.onboardingName,
                property_area_size_m2: propertyDetails.propertyAreaSizeM2,
                payment_method: formValues.paymentMethod as PaymentMethod,
                // bed_linen_value: currencyToNumber(formValues.bedlinenPrice),
                bed_linen_photo: photos?.uid !== undefined ? photos?.uid : null,
                onboarding_contact_phonenumber: propertyDetails.onboardingPhone,
                // photographer_value: currencyToNumber(formValues.photographerValue),
                // full_inspection_value: currencyToNumber(formValues.fullInspectionValue),
                implantation_fee_total_value:
                  currencyToNumber(formValues?.totalImplantationFee),
                payment_installments: formValues.paymentMethod === 'Installments' ? formValues.qtdInstallments : 0,
              });

              toast.success('Cadastro realizado com sucesso!');
              setIdNewOwner(-1);
              setValues({} as Values);
              setInfosPipeDrive({} as PipedriveOwnerInfo);
              setOwner({} as GetOwnersData);
              setPropertyDetails({} as PropertyInformation);
              handleClickPhase(0);
              removeFormikBackup();
              endCallback();
            } catch (e: unknown) {
              if (e instanceof Error) {
                toastErrorRequest(e);
                if (rules.id) {
                  await deletePropertyRules(rules.id);
                }
                if (property.id) {
                  await deletePropertyManager(property.id);
                }
                if (propertyAddress?.id) {
                  await deleteAddress(propertyAddress.id);
                }
              }
            }
          } catch (e: unknown) {
            if (e instanceof Error) {
              toastErrorRequest(e);
              if (property.id) {
                await deletePropertyManager(property.id);
              }
              if (propertyAddress?.id) {
                await deleteAddress(propertyAddress.id);
              }
            }
          }
        } catch (e: unknown) {
          if (e instanceof Error) {
            toastErrorRequest(e);
            if (propertyAddress?.id && propertyAddress?.id !== -1) {
              await deleteAddress(propertyAddress.id);
            }
          }
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          toastErrorRequest(e);
        }
      }
    },
  });

  // const calculateTotalImplementationValue = () => {
  //   const total = parseFloat(currencyToNumber(formik.values.setupValue).toString())
  // + parseFloat(currencyToNumber(formik.values.bedlinenPrice).toString())
  // + parseFloat(currencyToNumber(formik.values.photographerValue).toString())
  // + parseFloat(currencyToNumber(formik.values.fullInspectionValue).toString());
  //   return total;
  // };

  const handleSearchHost = () => {
    const resultFilter = hostsList.filter((item) => {
      const currentName = item?.name?.toLowerCase()?.trim() || '';
      const typedName = searchTerm?.toLowerCase()?.trim() || '';
      return currentName.includes(typedName);
    });

    setListFiltered(resultFilter);
  };

  const removeHost = () => {
    formik.setFieldValue('host', undefined);

    setValues({
      ...values,
      host: {} as RequestHost,
    });
  };

  const handleUpdateHost = (item: RequestHost) => {
    formik.setFieldValue('host', item.id);

    setValues({
      ...values,
      host: item,
    });
  };

  const handleDeleteFile = (fileId: string) => {
    deleteFile(fileId);
    setPhotos(photoInitialValues);
  };

  useEffect(() => {
    async function getAllHosts() {
      const result = await getHosts();
      setHostsList(result);
    }
    getAllHosts();
  }, []);

  useEffect(() => {
    if (formik.values.host !== undefined) {
      setHostRequired(false);
    }
  }, [formik.values.host]);

  useEffect(() => {
    if (photos?.uid !== undefined) {
      handleDeleteFile(photos.id);
    }
    setDocumentPhotoRequired(formik.values.haveBedding);
  }, [formik.values.haveBedding]);

  useEffect(() => {
    if (uploadedFiles.length !== 0) {
      setDocumentPhotoRequired(false);
    }
  }, [uploadedFiles]);

  useEffect(() => {
    setFormikBackup(formik.values);
    function handleHaveRequiriedFields() {
      switch (formik.values.paymentMethod) {
        case 'Installments': {
          return true;
        }
        case 'Bank_Slip': {
          return true;
        }
        case 'Discount_Rate': {
          return true;
        }
        case 'PIX': {
          return true;
        }
        case 'Credit_Card': {
          return true;
        }
        case 'On_Budget': {
          return true;
        }
        default: {
          return false;
        }
      }
    }
    setIsButtonDisabled(handleHaveRequiriedFields());
  }, [formik.values]);

  useEffect(() => {
    const value = infosPipeDrive?.implantation_fees?.implantation_fee_value;
    if (value) {
      const valueToCurrence = numberToCurrency(value);
      formik.setFieldValue('totalImplantationFee', valueToCurrence);
    }
  }, [infosPipeDrive]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <DeploymentFeeValuesContainer>
        <TitleContainer>
          <Title>Taxas e comissões</Title>
          <Border />
        </TitleContainer>
        <ContentContainer>
          <BoxContainer>
            <SimpleSelect
              id="plan"
              label="Plano Escolhido"
              placeholder="Selecione"
              disabled={false}
              options={[
                { value: 'Full', valueLabel: 'Full' },
                { value: 'Mid', valueLabel: 'Mid' },
                { value: 'Light', valueLabel: 'Light' },
                { value: 'Digital_Management', valueLabel: 'Gestão Digital' },
                { value: 'Essencial', valueLabel: 'Essencial' },
                { value: 'Plus', valueLabel: 'Plus' },
                { value: 'Pool', valueLabel: 'Pool' },
              ].sort()}
              formik={formik}
            />
          </BoxContainer>
          {/* <QuadriBox>
            <BoxContainer>
              <OnboardingTextField
                required
                mask="money"
                // id="setupValue"
                formik={formik}
                label="Valor setup"
                placeholder="R$ 00,00"
                // value={formik.values.setupValue}
              />
              <SubText>Com desconto, caso tenha</SubText>
            </BoxContainer>
            <BoxContainer>
              <OnboardingTextField
                // id="photographerValue"
                label="Valor fotógrafo"
                required
                mask="money"
                placeholder="R$ 00,00"
                formik={formik}
                // value={formik.values.photographerValue}
              />
              <SubText>Com desconto, caso tenha</SubText>
            </BoxContainer>
            <BoxContainer>
              <OnboardingTextField
                id="bedlinenPrice"
                label="Valor da roupa de cama"
                required
                mask="money"
                placeholder="R$ 00,00"
                isLargeText
                formik={formik}
                value={formik.values.bedlinenPrice}
              />
              <SubText>Com desconto, caso tenha</SubText>
            </BoxContainer>
            <BoxContainer>
              <OnboardingTextField
                id="fullInspectionValue"
                label="Valor da vistoria completa"
                required
                mask="money"
                placeholder="R$ 00,00"
                isLargeText
                formik={formik}
                value={formik.values.fullInspectionValue}
              />
              <SubText>Com desconto, caso tenha</SubText>
            </BoxContainer>
          </QuadriBox> */}
          <TotalBoxContainer>
            <TotalValueText>Taxa de adesão * (R$)</TotalValueText>
            {/* <TotalValueBorder />
            <TotalValuePrice>
              {numberToCurrency(calculateTotalImplementationValue())}
            </TotalValuePrice> */}
            <CustomInputMask
              option="money"
              formik={formik}
              id="totalImplantationFee"
              placeholder={formik.values.totalImplantationFee ? formik.values.totalImplantationFee : 'Insira o valor em R$'}
            />
          </TotalBoxContainer>
          <AlertContainer>
            <img src={AlertIcon} alt="Alert icon" />
            <p>
              Favor enviar comprovante de pagamento por email
              para administrativo@seazone.com.br
            </p>
          </AlertContainer>

          <End />
          <BoxContainer>
            <BoxContent>
              <BoxHeader>
                <BoxTitle>
                  Descrição de itens cobrados na taxa de implantação *
                </BoxTitle>
              </BoxHeader>

              <FormGridRow grid>
                <Grid item xs={12} sm={6}>
                  <BoxContent>
                    <SwitchContainer flexDirection="column">
                      <label htmlFor="haveBedding">Tem roupa de cama ?</label>
                      <OnboardingSwitch value={formik.values.haveBedding} id="haveBedding" formik={formik} />
                    </SwitchContainer>
                    <DescriptionContainer>
                      <OnboardingTextField
                        formik={formik}
                        id="description"
                        label="Descrição:"
                        placeholder="Digite aqui"
                        type="textarea"
                        value={formik.values.description}
                      />
                    </DescriptionContainer>
                  </BoxContent>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <BoxContent>
                    <UploadText>{`Imagem: ${formik.values.haveBedding ? '*' : ''}`}</UploadText>
                    <UploadContainer>
                      <Upload
                        dataCy="input-receipt-file"
                        file={photos}
                        setFile={(file: FileProps) => handleSelectFile(file)}
                        accept={['image/*', '.pdf']}
                        defaultPreview={addPhotoSketchIcon}
                        disableDragAndDrop={!formik.values.haveBedding || photos?.uid !== undefined}
                      />
                    </UploadContainer>

                    <UploadText marginTop={1}>
                      {`Foto da lista de roupas de cama e valor ${formik.values.haveBedding ? '*' : ''}`}
                    </UploadText>

                    {uploadedFiles.length !== 0 && (
                      <UploadFile>
                        <p>{uploadedFiles[0].name}</p>
                        <TrashButton onClick={() => handleDeleteFile(uploadedFiles[0].id)}>
                          <img src={TrashIcon} alt="trashIcon" />
                        </TrashButton>
                      </UploadFile>
                    )}

                    {documentPhotoRequired && formik.values.haveBedding && (
                      <ErrorContainer>
                        <p className="error">
                          <ErrorIcon />
                          {' '}
                          Faça o upload de uma imagem!
                        </p>
                      </ErrorContainer>
                    )}
                  </BoxContent>
                </Grid>
              </FormGridRow>
            </BoxContent>
          </BoxContainer>

          <SearchBoxContainer>
            <BoxTitle>Anfitrião responsável *</BoxTitle>
            <SearchContainer>
              <SearchBar>
                <img src={SearchIcon} alt="icone de pesquisa" />
                {(values?.host?.name || values?.host?.id) && (
                  <AreaSelected>
                    <CardHostSelected>
                      <p>{values?.host?.name || ''}</p>
                      <Tooltip text="Remover anfitrião e escolher outro">
                        <button type="button" onClick={() => removeHost()}>x</button>
                      </Tooltip>
                    </CardHostSelected>
                  </AreaSelected>
                )}
                {(!values?.host?.name && !values?.host?.id) && (
                  <input type="text" onChange={(event) => setSearchTerm(event.target.value)} name="name" placeholder="" />
                )}
              </SearchBar>
              <ButtonDiv>
                <FormButton type="button" onClick={() => handleSearchHost()}>Buscar</FormButton>
              </ButtonDiv>
            </SearchContainer>
            {hostRequired && (
              <ErrorContainer>
                <p className="error">
                  <ErrorIcon />
                  {' '}
                  Selecione um anfitrião!
                </p>
              </ErrorContainer>
            )}
            {listFiltered.length >= 1 && <h1>Resultados:</h1>}
            {listFiltered?.sort()?.map((item) => (
              <ResultsText key={item.id} onClick={() => handleUpdateHost(item)}>
                <p>{item.name}</p>
              </ResultsText>
            ))}
          </SearchBoxContainer>
          <End />
          <div style={{
            display: 'flex', gap: '1rem', width: '100%', marginBottom: '-2rem',
          }}
          >
            <BoxContainer>
              <OnboardingTextField
                id="commissions"
                label="Comissão sobre reservas"
                required
                formik={formik}
                value={formik.values.commissions}
                placeholder="Ex.: 10%"
                endAdornment={
                  <Percent>%</Percent>
              }
              />
            </BoxContainer>
            <BoxContainer>
              <OnboardingTextField
                disabled={formik.values.plan === 'Digital_Management'}
                id="hostReservationComissionFee"
                label="Comissão do anfitrião"
                required
                formik={formik}
                value={formik.values.plan === 'Digital_Management' ? 0 : 8}
                placeholder="Ex.: 8%"
                endAdornment={
                  <Percent>%</Percent>
              }
                tooltip={
                  `Padrão: 8%
                  Madego: Plano Essencial - Dividir a comissão do imóvel por dois, se o valor for "quebrado" colocar um a mais para Seazone.
                  Exemplo: Comissão do imóvel é 25%, então comissão anfitrião é 12%. 
                  Plano Plus - Diminuir 2% da comissão do imóvel e divide por 2, seguindo o racional do Plano Essencial.`
}
              />
            </BoxContainer>
          </div>
          <SelectBoxContainer>
            <OnboardingPaymentMethod
              id="paymentMethod"
              labelPlacement="bottom"
              formLabel="Forma de pagamento da taxa *"
              formik={formik}
              options={[
                { label: 'À vista', value: 'On_Budget', layout: '1' },
                { label: 'À prazo', value: 'Installments', layout: '2' },
                { label: 'Abatimento nas reservas', value: 'Discount_Rate', layout: '3' },
                { label: 'Boleto', value: 'Bank_Slip', layout: '1' },
                { label: 'Cartão de crédito', value: 'Credit_Card', layout: '1' },
                { label: 'Pix', value: 'PIX', layout: '1' },
              ]}
            />
          </SelectBoxContainer>
          <End />
        </ContentContainer>
      </DeploymentFeeValuesContainer>
      <ButtonSection>
        <FormButton disable={!isButtonDisabled} type="submit">Salvar</FormButton>
      </ButtonSection>
    </form>
  );
};

export default CardDeploymentFeeValues;
