import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
`;

export const Form = styled.form``;

export const Label = styled.label`
  font-weight: 400;
  color: ${({ theme }) => theme.palette.blue._250.hex()};
  font-size: 14px;
  line-height: 17.5px;
  padding-bottom: 8px;
`;

export const PropertyInformationContainer = styled.div`
  animation: ${fadeIn} 1s 0.2s ease forwards;
  margin-top: 27px;
  h1 {
    font-weight: ${({ theme }) => theme.fonts.weights.bold};
    font-size: 18px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  width: 100%;
`;

export const Title = styled.h1`
  width: 30%;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.palette.blue._250.hex()};
`;

export const Border = styled.div`
  border-bottom: 0.5px solid ${({ theme }) => theme.palette.grey._345.hex()};
  width: 70%;
  margin-bottom: 10px;
  margin-left: 20px;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100px;
`;

export const BoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => theme.palette.grey._375.hex()};
  border-radius: 10px;
  padding: 18px 16px;
`;

export const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: auto;
`;

export const BoxHeader = styled.div`
  width: 100%;
`;

export const BoxTitle = styled.h2`
  color: ${({ theme }) => theme.palette.blue._250.hex()};
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-weight: 400;
  font-size: 14px;
  line-height: 17.5px;
  padding-bottom: 8px;
`;

export const DoubleBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;

export const QuantityContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;
  width: 100%;
  grid-auto-flow: row;
`;

export const BoxQuantityContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => theme.palette.grey._375.hex()};
  border-radius: 10px;
  padding: 18px 0;
`;

export const QuatityTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const QuatityText = styled.h2`
  align-self: flex-start;
  margin-bottom: 16px;
  margin-left: 10px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.blue._250.hex()};
`;

export const InfoIconContainer = styled.div`
  width: 16px;
  height: 16px;
  margin: 0 1rem 1rem 0;

  svg {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.grey._325.hex()};
    width: 100%;
    height: 100%;
  }

  :hover {
    svg {
      color: ${({ theme }) => theme.palette.blue._850.hex()};
      transition: 0.6s;
    }
  }
`;

export const SwitchContainer = styled.div<{ flexDirection?: string }>`
  grid-column-start: 1;
  grid-column-end: 2;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  align-items: center;
  justify-content: space-between;
  width: 100%;

  label {
    width: 100%;
    text-align: start;
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-weight: ${({ theme }) => theme.fonts.weights.normal};
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.02em;
    color: ${({ theme }) => theme.palette.blue._250.hex()};
  }
`;

export const End = styled.div`
  padding: 20px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  position: fixed;
  bottom: 30px;
  left: 68vw;
  right: 0;
  z-index: 9999;
  button {
    min-width: 100px;
    max-height: 35px;
  }
`;

export const CategoryField = styled.div`
  width: 100%;
  margin-top: 1rem;
  > div {
    .subTitle {
      width: 100% !important;
      text-align: start !important;
      font-family: ${({ theme }) => theme.fonts.familys._6} !important;
      font-weight: ${({ theme }) => theme.fonts.weights.normal} !important;
      font-style: normal !important;
      font-size: 14px !important;
      line-height: 17px !important;
      letter-spacing: -0.02em !important;
      color: ${({ theme }) => theme.palette.blue._250.hex()} !important;
    }
  }
`;
